import React from 'react';
import cx from 'classnames';
import { Link } from 'gatsby';
import Featured from '../Featured/Featured';
import styles from './hero.module.scss';

const Hero = ({ fullScreen = false, heading, image, ctaButton, featured }) => {
  return (
    <div
      className={cx(styles.base, { [styles.fullScreen]: fullScreen })}
      style={{
        backgroundImage: `url(${image?.fixed?.src || image.file.url})`,
      }}
    >
      <div className={cx('container', styles.inner)}>
        <h1>{heading}</h1>
        {ctaButton && (
          <Link
            to={ctaButton.buttonLink}
            className={cx(styles.cta, 'btn btn--large')}
          >
            {ctaButton.label}
          </Link>
        )}
        {featured && <Featured items={featured} />}
      </div>
    </div>
  );
};

export default Hero;
