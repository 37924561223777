import React, { useContext } from 'react';
import { Link, graphql } from 'gatsby';
import cx from 'classnames';
import Spinner from '../../icons/Spinner';
import styles from './styles.module.scss';
import { CartContext } from '../../contexts/CartContext';

const CartItem = ({ item, remove, update }) => {
  const {
    cart: { isAdding, isRemoving, currentLineItem },
  } = useContext(CartContext);

  const itemLink = item?.vendor?.match(/m[aā]obox/gi)
    ? '/subscribe'
    : `/product/${item.handle}`;

  const [quantity, setQuantity] = React.useState(item.quantity);
  const [loading, setLoading] = React.useState(false);

  const onChange = event => {
    const input = event.target.value.replace(/\D/g, '');
    if (!input || !isNaN(input)) {
      setQuantity(input);
    }
  };

  const onBlur = () => {
    if (parseInt(quantity) === item.quantity) return;

    if (isNaN(parseInt(quantity))) {
      setQuantity(item.quantity);
      return;
    }

    if (quantity === 0) {
      remove(item.id);
    } else {
      update(item.id, quantity);
    }
  };

  React.useEffect(() => {
    if (!isAdding) {
      setLoading(false);
    } else if (isAdding && item.id === currentLineItem) {
      setLoading(true);
    }
  }, [isAdding]);

  React.useEffect(() => {
    setQuantity(item.quantity);
  }, [item]);

  return (
    <li className={styles.item}>
      <Link to={itemLink}>
        <figure>
          <img
            src={item.variant?.image?.src || 'https://via.placeholder.com/150'}
          />
        </figure>
      </Link>
      <div className={styles.description}>
        {item.hasOutOfStockVariants && <span className="tag">Pre-order</span>}
        <Link to={itemLink} className={styles.name}>
          {item.title}
        </Link>
        {item.totalVariants > 1 && (
          <span className="d-block">{item.variant.title}</span>
        )}
        <button
          type="button"
          className="btn btn--small btn--secondary mt-half"
          onClick={() => remove(item.id)}
        >
          {isRemoving && currentLineItem === item.id && <Spinner />} Remove
        </button>
      </div>
      <div className={styles.quantity}>
        <input
          className={styles.quantityInput}
          type="text"
          value={quantity}
          onChange={onChange}
          onBlur={onBlur}
        />
      </div>
      <div className={styles.price}>
        {loading ? (
          <Spinner />
        ) : (
          `$${(Number(item.variant.price.attrs.amount) * item.quantity).toFixed(
            2
          )}`
        )}
      </div>
    </li>
  );
};

export default CartItem;
