import React from 'react';
import cx from 'classnames';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { useInput } from '../../hooks/useInput';

import styles from './styles.module.scss';

const SignupForm = ({ heading, label }) => {
  const [formStatus, setFormStatus] = React.useState(null);
  const { value: email, bind, reset } = useInput('');

  const isValidEmail = () => {
    // claims to be the 👌 best email regex https://emailregex.com/
    const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    return regex.test(email);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setFormStatus(null);

    if (isValidEmail()) {
      const response = await addToMailchimp(email);
      setFormStatus(response);
      if (
        response.result === 'error' &&
        (response.msg.includes('already subscribed') ||
          response.msg.includes('too many'))
      ) {
        setFormStatus({ result: 'success', msg: 'Thanks for subscribing!' });
        reset();
      }
    } else {
      setFormStatus({
        result: 'invalid',
        msg: 'Please use a valid email address.',
      });
    }
  };
  return (
    <div className={styles.base}>
      <h4>Join our mailing list for updates, discounts & more!</h4>
      <form onSubmit={handleSubmit}>
        <input
          className={cx('input--text', {
            'input--error': formStatus?.result === 'invalid',
          })}
          type="text"
          name="email"
          placeholder="Your email address"
          {...bind}
        />
        <button type="submit" className={cx('btn', styles.submitBtn)}>
          Sign up
        </button>
      </form>
      {formStatus && (
        <div className={styles.status}>
          {formStatus.result !== 'error' ? (
            <span className={formStatus.result}>{formStatus.msg}</span>
          ) : (
            <>
              Oops, something went wrong.{' '}
              <a
                href="https://instagram.com/maobox.sg"
                target="_blank"
                rel="noreferrer"
              >
                Send us a DM
              </a>{' '}
              to let us know!
            </>
          )}
        </div>
      )}
    </div>
  );
};
export default SignupForm;
