import React from 'react';
import cx from 'classnames';

const Box = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="65.419" height="58.269">
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1="24.481"
        y1="27.242"
        x2="71.467"
        y2="59.767"
      >
        <stop offset=".048" stopColor="#deae79" />
        <stop offset=".155" stopColor="#d9a772" />
        <stop offset=".432" stopColor="#d19a66" />
        <stop offset=".693" stopColor="#ce9662" />
      </linearGradient>
      <path
        fill="url(#a)"
        d="M65.419 29.599l-33.648 6.885v21.785l33.648-7.921z"
      />
      <linearGradient
        id="b"
        gradientUnits="userSpaceOnUse"
        x1="-1.551"
        y1="50.676"
        x2="48.473"
        y2="26.582"
      >
        <stop offset=".151" stopColor="#e5b785" />
        <stop offset=".589" stopColor="#deae79" />
        <stop offset=".755" stopColor="#dba975" />
        <stop offset=".943" stopColor="#d29c67" />
        <stop offset="1" stopColor="#ce9662" />
      </linearGradient>
      <path fill="url(#b)" d="M0 26.284l31.771 10.2v21.785L0 46.37z" />
      <linearGradient
        id="c"
        gradientUnits="userSpaceOnUse"
        x1="13.529"
        y1="12.372"
        x2="19.426"
        y2="45.396"
      >
        <stop offset="0" stopColor="#deb389" />
        <stop offset=".07" stopColor="#d6a97e" />
        <stop offset=".286" stopColor="#c28f62" />
        <stop offset=".411" stopColor="#ba8557" />
        <stop offset=".539" stopColor="#a87649" />
        <stop offset=".687" stopColor="#9b6b3e" />
        <stop offset=".819" stopColor="#96673a" />
      </linearGradient>
      <path fill="url(#c)" d="M0 26.284l31.771 10.2.704-.148-1.424-15.527z" />
      <linearGradient
        id="d"
        gradientUnits="userSpaceOnUse"
        x1="14.568"
        y1=".525"
        x2="16.347"
        y2="43.314"
      >
        <stop offset="0" stopColor="#deb389" />
        <stop offset=".07" stopColor="#d6a97e" />
        <stop offset=".286" stopColor="#c28f62" />
        <stop offset=".411" stopColor="#ba8557" />
        <stop offset=".539" stopColor="#a87649" />
        <stop offset=".687" stopColor="#9b6b3e" />
        <stop offset=".819" stopColor="#96673a" />
      </linearGradient>
      <path fill="url(#d)" d="M0 26.284l30.948 9.936.103-15.411z" />
      <linearGradient
        id="e"
        gradientUnits="userSpaceOnUse"
        x1="27.686"
        y1="9.962"
        x2="33.558"
        y2="42.843"
      >
        <stop offset="0" stopColor="#deb389" />
        <stop offset=".07" stopColor="#d6a97e" />
        <stop offset=".286" stopColor="#c28f62" />
        <stop offset=".411" stopColor="#ba8557" />
        <stop offset=".539" stopColor="#a87649" />
        <stop offset=".687" stopColor="#9b6b3e" />
        <stop offset=".819" stopColor="#96673a" />
      </linearGradient>
      <path
        fill="url(#e)"
        d="M32.475 36.336l-1.424-15.527-.103 15.411.823.264z"
      />
      <linearGradient
        id="f"
        gradientUnits="userSpaceOnUse"
        x1="52.411"
        y1="9.991"
        x2="42.451"
        y2="42.129"
      >
        <stop offset=".151" stopColor="#deae79" />
        <stop offset=".442" stopColor="#d29c68" />
        <stop offset=".528" stopColor="#ce9662" />
        <stop offset=".988" stopColor="#a87444" />
      </linearGradient>
      <path
        fill="url(#f)"
        d="M64.273 29.562l-33.216-8.489-.008.001-.101 14.877.822.264z"
      />
      <linearGradient
        id="g"
        gradientUnits="userSpaceOnUse"
        x1="53.565"
        y1="54.853"
        x2="17.927"
        y2="8.866"
      >
        <stop offset=".151" stopColor="#deae79" />
        <stop offset=".485" stopColor="#d29c68" />
        <stop offset=".584" stopColor="#ce9662" />
        <stop offset=".59" stopColor="#cc9460" />
        <stop offset=".682" stopColor="#b27d4c" />
        <stop offset=".736" stopColor="#a87444" />
      </linearGradient>
      <path
        fill="url(#g)"
        d="M31.057 21.073l33.217 8.489-32.504 6.653L1.105 26.37l29.952-5.297m-.006-.264L0 26.284l31.771 10.2 33.647-6.885-34.367-8.79z"
      />
      <linearGradient
        id="h"
        gradientUnits="userSpaceOnUse"
        x1="29.327"
        y1="-18.642"
        x2="23.187"
        y2="49.335"
      >
        <stop offset=".025" stopColor="#deb389" />
        <stop offset=".066" stopColor="#daad83" />
        <stop offset=".38" stopColor="#ba8557" />
        <stop offset=".501" stopColor="#ac794b" />
        <stop offset=".712" stopColor="#96673a" />
      </linearGradient>
      <path
        fill="url(#h)"
        d="M.272 26.371L19.86 4.417 52.582.518 31.057 21.073z"
      />
      <g>
        <linearGradient
          id="i"
          gradientUnits="userSpaceOnUse"
          x1="3.438"
          y1="-3.091"
          x2="24.544"
          y2="38.123"
        >
          <stop offset=".151" stopColor="#e5b785" />
          <stop offset=".589" stopColor="#deae79" />
          <stop offset=".755" stopColor="#dba975" />
          <stop offset=".943" stopColor="#d29c67" />
          <stop offset="1" stopColor="#ce9662" />
        </linearGradient>
        <path
          fill="url(#i)"
          d="M19.846 4.407l-.46-.319L0 26.284l.272.087 19.36-21.699 5.357 4.521.745-4.835z"
        />
        <linearGradient
          id="j"
          gradientUnits="userSpaceOnUse"
          x1="22.107"
          y1="-18.807"
          x2="56.949"
          y2="26.581"
        >
          <stop offset=".151" stopColor="#deae79" />
          <stop offset=".442" stopColor="#d29c68" />
          <stop offset=".528" stopColor="#ce9662" />
          <stop offset=".988" stopColor="#a87444" />
        </linearGradient>
        <path
          fill="url(#j)"
          d="M25.734 4.358l-.745 4.835 33.171-4.71.136-.395z"
        />
      </g>
      <linearGradient
        id="k"
        gradientUnits="userSpaceOnUse"
        x1="27.512"
        y1="-12.877"
        x2="79.525"
        y2="65.011"
      >
        <stop offset=".151" stopColor="#deae79" />
        <stop offset=".442" stopColor="#d29c68" />
        <stop offset=".528" stopColor="#ce9662" />
        <stop offset=".988" stopColor="#a87444" />
      </linearGradient>
      <path
        fill="url(#k)"
        d="M52.001 0L19.386 4.088l5.603 4.729 33.307-4.729z"
      />
    </svg>
  );
};

export default Box;
