import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import cx from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import './global.scss';

import NavBar from '../components/NavBar';
import CartDrawer from '../components/CartDrawer/CartDrawer';
import Footer from '../components/Footer/Footer';
import Hero from '../components/Hero/Hero';

import { CartContext, useToggleCart } from '../contexts/CartContext';

import { globalHistory, useLocation } from '@reach/router';

const DefaultLayout = ({
  children,
  contentfulPage,
  metaData = {},
  pageTitle,
}) => {
  const location = useLocation();

  const data = useStaticQuery(graphql`
    query FooterQuery {
      contentfulPageMeta(contentful_id: { eq: "EQYwtAYiXYTcKPHLTuYkQ" }) {
        title
        metaDescription
        image {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
      contentfulHeader(contentful_id: { eq: "7Fke7MbTZuGKvXou8uJ1u7" }) {
        id
        announcement
        title
      }
      contentfulFooter(contentful_id: { eq: "3tP8j1ueOe63EQq5tds2tg" }) {
        navigation {
          ... on ContentfulPage {
            contentful_id
            title
            slug
          }
          ... on ContentfulHowItWorksPage {
            contentful_id
            title
            slug
          }
        }
        legal {
          title
          slug
          contentful_id
        }
      }
    }
  `);

  const {
    title = data.contentfulPageMeta.title,
    metaDescription = data.contentfulPageMeta.metaDescription,
    image = data.contentfulPageMeta.image,
  } = metaData;

  const {
    cart: { isDrawerOpen },
  } = useContext(CartContext);

  const toggleCart = useToggleCart();

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      toggleCart(false);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>MāoBox | {title || pageTitle}</title>
        <link rel="icon" type="image/png" href="/favicon.png" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap"
          rel="stylesheet"
        />
        <meta
          name="description"
          content={metaDescription || data.contentfulPageMeta.metaDescription}
        />
        <meta property="og:title" content={`MāoBox | ${title || pageTitle}`} />
        <meta property="og:image" content={`https:${image.file.url}`} />
        <meta
          property="og:image:width"
          content={image.file.details.image.width}
        />
        <meta
          property="og:image:height"
          content={image.file.details.image.height}
        />
        <meta
          property="og:description"
          content={metaDescription || data.contentfulPageMeta.metaDescription}
        />
        <meta property="og:url" content={location.href} />
        <meta property="og:type" content="website" />

        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-83551008-1"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){
            dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', 'UA-83551008-1');
          `}
        </script>
        {/* <!-- Facebook Pixel Code --> */}
        <script>
          {`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1276882055655802');
            fbq('track', 'PageView');
          `}
        </script>
        <noscript>
          {`
            <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=1276882055655802&ev=PageView&noscript=1"/>
          `}
        </noscript>
        {/* <!-- End Facebook Pixel Code --> */}
        <script>
          {`
          var chatbox = document.getElementById('fb-customer-chat');
          chatbox.setAttribute("page_id", "1657821274438678");
          chatbox.setAttribute("attribution", "biz_inbox");

          window.fbAsyncInit = function() {
            FB.init({
              xfbml            : true,
              version          : 'v11.0'
            });
          };

          (function(d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = 'https://connect.facebook.net/en_GB/sdk/xfbml.customerchat.js';
            fjs.parentNode.insertBefore(js, fjs);
          }(document, 'script', 'facebook-jssdk'));
        `}
        </script>
        <body className={cx({ 'no-scroll': isDrawerOpen })} />
      </Helmet>

      <NavBar />
      <CartDrawer isOpen={isDrawerOpen} />
      <div
        className={cx('body', {
          'has-announcement': !!data.contentfulHeader.announcement,
        })}
      >
        {contentfulPage?.hero && <Hero {...contentfulPage.hero} />}
        {children}
        <Footer
          items={data.contentfulFooter.navigation}
          links={data.contentfulFooter.legal}
        />
      </div>
      {isDrawerOpen && (
        <div className="overlay" onClick={() => toggleCart(false)}></div>
      )}
    </>
  );
};

export default DefaultLayout;
