import React from 'react';
import { Link } from 'gatsby';
import cx from 'classnames';
import SignupForm from '../SignupForm/SignupForm';
import styles from './styles.module.scss';

const Footer = ({ items, links }) => {
  const year = new Date().getFullYear();

  return (
    <div className={cx(styles.base)}>
      <div
        className={cx(
          styles.inner,
          'container d-flex d-align-start d-flex-between'
        )}
      >
        <nav>
          <ul>
            {items.length &&
              items.map(item => (
                <li key={item.contentful_id}>
                  <Link to={item.slug}>{item.title}</Link>
                </li>
              ))}
            <li>
              <Link to="/blog">MāoBlog</Link>
            </li>
            <li>
              <a href="mailto:hi@maobox.sg">Contact</a>
            </li>
          </ul>
          <div className={styles.social}>
            <a
              className={styles.socialLink}
              href="https://www.facebook.com/maobox.sg/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="icon icon--small"
                alt="MāoBox Facebook"
                src="/fb.svg"
              />
            </a>
            <a
              className={styles.socialLink}
              href="https://www.instagram.com/maobox.sg/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="icon icon--small"
                alt="MāoBox Instagram"
                src="/ig.svg"
              />
            </a>
          </div>
        </nav>

        <SignupForm />
      </div>
      <div className={styles.copyright}>
        <div className={cx(styles.inner, 'container d-flex d-flex-between')}>
          <span>
            &copy; 2016 &ndash; {year} MāoBox (UEN53348522C).
            All rights reserved.
          </span>
          <span>
            {links.map(({ title, slug }) => (
              <Link to={slug}>{title}</Link>
            ))}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
