import React from 'react';

const Close = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
    >
      <rect
        x="-2.808"
        y="8.192"
        transform="matrix(0.7071 0.7071 -0.7071 0.7071 9.1924 -3.8076)"
        width="24"
        height="2"
      />
      <rect
        x="-2.808"
        y="8.192"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -3.8076 9.1924)"
        width="24"
        height="2"
      />
    </svg>
  );
};

export default Close;
