import React from 'react';
import cx from 'classnames';
import styles from './styles.module.scss';

const Featured = ({ items }) => {
  return (
    <div className={styles.base}>
      <div className={styles.header}>Featured in</div>
      <div className={cx('d-flex', styles.media)}>
        {items.map(item => (
          <a href={item.link} className={styles.item} target="_blank">
            <img
              className={styles.logo}
              src={item.logo.fluid.src}
              alt={item.mediaName}
            />
          </a>
        ))}
      </div>
    </div>
  );
};

export default Featured;
