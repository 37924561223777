import React from 'react';

const Menu = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24px"
      height="20px"
      x="0px"
      y="0px"
      viewBox="0 0 24 20"
    >
      <g>
        <rect y="0" width="24" height="2" />
        <rect y="9" width="24" height="2" />
        <rect y="18" width="24" height="2" />
      </g>
    </svg>
  );
};

export default Menu;
